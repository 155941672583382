/**
 * Created by r4zi4l on 12.07.2021
 */

var IngredientsList = cleverapps.Layout.extend({
    ctor: function (maker) {
        this.maker = maker;

        var icons = this.icons = maker.recipe.ingredients.map(function (ingredient) {
            return new IngredientOrderIcon(ingredient, maker);
        });

        this._super(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.IngredientsList.margin
        });

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(cleverapps.styles.IngredientsList);

        this.setCascadeOpacityEnabled(true);
    },

    updateAmounts: function () {
        if (this.maker.state === MakesOrder.STATE_RECIPE) {
            this.icons.forEach(function (icon) {
                icon.updateAmount(Map2d.currentMap.customers.getStockAmount(icon.ingredient));
            });
        }
    },

    listIcons: function () {
        return this.icons;
    },

    removeAnimated: function () {
        this.icons.forEach(function (ingredient) {
            ingredient.beforeRemoveAnimated();
        });

        this.runAction(
            new cc.Spawn(
                new cc.MoveBy(0.4, cleverapps.styles.IngredientsList.delta.x, 0).easing(cc.easeOut(2)),
                new cc.FadeOut(0.4),
                new cc.Sequence(
                    new cc.ScaleTo(0.3, 0.5).easing(cc.easeIn(2)),
                    new cc.RemoveSelf()
                )
            )
        );
    },

    showAnimated: function () {
        this.x -= cleverapps.styles.IngredientsList.delta.x;
        this.setScale(0.8);
        this.setOpacity(0.3);

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.Spawn(
                    new cc.FadeIn(0.1),
                    new cc.MoveBy(0.3, cleverapps.styles.IngredientsList.delta.x, 0).easing(cc.easeIn(2)),
                    new cc.ScaleTo(0.3, 1)
                )
            )
        );
    }
});

cleverapps.styles.IngredientsList = {
    delta: {
        x: 250
    },
    x: { align: "center", dx: 0 },
    y: { align: "center", dy: 60 },
    margin: 30
};
