/**
 * Created by r4zi4l on 23.08.2021
 */

WorkerView.prototype.miningEffects = function () {
    return cleverapps.skins.getSlot("miningEffects") || {
        animation: "chop",
        sound: bundles.mineable.urls.mining_start_effect
    };
};

WorkerView.prototype.buildingEffects = function () {
    return {
        sound: bundles.buildable.urls.build_effect
    };
};
